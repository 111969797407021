import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Icon from "../components/Icon";
import PageLayout from "../components/PageLayout";
import Carousel from "../components/Carousel";
import Seo from "../components/Seo";

const GeneratedPage = ({ data, params }) => {
  const { edges } = data.allMarkdownRemark;
  const currentBike = edges.find(
    ({ node }) => node.frontmatter.slug === params.frontmatter__slug
  );
  const { title, description, price, featuredImage, images, sold, slug } =
    currentBike.node.frontmatter;
  const featImg = getImage(featuredImage);

  return (
    <PageLayout>
      <Seo title={title} description={description} slug={slug} />
      <section className="product-page">
        <div className="product-page__gallery">
          <Carousel autoSpeed={5500}>
            <GatsbyImage image={featImg} alt={title} />
            {!!images &&
              images.map((image, i) => {
                if (!!image?.childImageSharp) {
                  return (
                    <GatsbyImage
                      image={image.childImageSharp.gatsbyImageData}
                      alt={`${title}-${i + 1}`}
                    />
                  );
                }
                return;
              })}
          </Carousel>
        </div>
        <div className="product-page__info">
          <h2>
            {title}
            <span>&nbsp; |&nbsp; &#36;{price}</span>
          </h2>
          <p>{description}</p>
          <h4>&#36;{price.toFixed(2)}</h4>
          {sold ? (
            <p>This bike has been sold.</p>
          ) : (
            <>
              <Link to={`/contact?bike=${title}`} className="button">
                Contact to Buy
              </Link>
              <p>
                Bikes can be picked up at the shop in Celina, OH, or can be
                shipped anywhere in the US at the buyer's expense. Paypal and
                Venmo are accepted.
              </p>
            </>
          )}
          <hr />
          <Link to="/#bikes">
            {" "}
            <Icon title="Previous Arrow" size={14} /> Back to Home
          </Link>
        </div>
      </section>
    </PageLayout>
  );
};

export default GeneratedPage;

export const query = graphql`
  query MyQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            description
            price
            sold
            title
            slug
            images {
              childImageSharp {
                gatsbyImageData
              }
            }
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
